const legend = {
  display: true,
  position: 'right',
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      weight: '800',
      size: 10
    },
    boxWidth: 10,
    generateLabels: (chart) => {
      const innerDataset = chart.data.datasets[1];
      if (innerDataset.label === 'UTM Values') {
        return innerDataset.labels.map((label, index) => ({
          text: label,
          fillStyle: innerDataset.backgroundColor[index],
          strokeStyle: innerDataset.backgroundColor[index],
          hidden: false
        }));
      }

      return null;
    }
  }
};

const tooltip = {
  callbacks: {
    label(tooltipItem) {
      const { dataset, datasetIndex } = tooltipItem;
      const label = dataset.labels[tooltipItem.dataIndex];
      const value = tooltipItem.raw;

      // Calculate percentage
      if (datasetIndex === 0) {
        // Outer ring
        const groupIndex = Math.floor(tooltipItem.dataIndex / 2);

        const innerRingDataset = tooltipItem.chart.data.datasets[1];
        const innerRingLabel = innerRingDataset.labels[groupIndex];

        const leads = dataset.data[groupIndex * 2];
        const unconverted = dataset.data[groupIndex * 2 + 1];

        const groupTotal = leads + unconverted;
        const percentage = ((value / groupTotal) * 100).toFixed(0);
        return ` ${label} (${innerRingLabel}): ${value} (${percentage}%)`;
      }

      if (datasetIndex === 1) {
        // Inner ring
        const total = dataset.data.reduce((acc, val) => acc + val, 0);
        const percentage = ((value / total) * 100).toFixed(0);
        return ` ${label}: ${value} (${percentage}%)`;
      }

      return ` ${label}: ${value}`;
    }
  }
};

export const chartOptions = {
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend,
    tooltip
  }
};

export const innerRingChartColors = [
  '#006D2C', '#238B45', '#41AE76', '#78C679',
  '#ADDD8E', '#D9F0A3', '#F7FCB9'
];
