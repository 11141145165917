import api from '../../../../../api';
import { getUtmContentReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';

export function fetchUtmContentReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmContentReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmContent: 'Wix', visitorsCount: fakeValue(100) },
    { utmContent: 'Landing Page', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}
