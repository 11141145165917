import api from '../../../../../api';
import {
  getVerifiedLeadsLocationQuery
} from '../../../../../graphql';
import { generateSampleLocation } from '../../../../../utils/sampleDataUtils';

export function fetchVerifiedLeadsLocationAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getVerifiedLeadsLocationQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  const center = { lat: 51.053525116000095, lng: -114.06900939556972 };
  const radiusInMeters = 10 * 1000; // 10km

  const data = [];
  for (let i = 0; i <= 20; i += 1) {
    const { lat, lng } = generateSampleLocation(center.lat, center.lng, radiusInMeters);
    data.push({ lat, lng });
  }

  return Promise.resolve(data);
}
