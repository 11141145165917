import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Collapse, FormGroup, Label, Row
} from 'reactstrap';
import Switch from 'rc-switch';
import i18n from 'i18n-js';

import { parseBoolean } from '../../../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.advancedSettings.reportSettings.index' };

const ReportSettings = ({ form, error, onTextChange }) => {
  const reportSettings = form.reportSettings || {};

  const onToggle = (name, checked) => {
    const newReportSettings = { ...reportSettings, [name]: checked };
    onTextChange({ target: { name: 'reportSettings', value: newReportSettings } });
  };

  return (
    <>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="enableReportsContainer">
            <Label sm={5} className="py-0">{i18n.t('enableReports', i18nOpts)}</Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(reportSettings.enabled)}
                onChange={(checked) => onToggle('enabled', checked)}
              />
            </Col>
          </FormGroup>

          <Collapse isOpen={parseBoolean(reportSettings.enabled)}>
            <FormGroup row id="enabledFinancialReportContainer">
              <Label sm={5} className="py-0">{i18n.t('financialReports', i18nOpts)}</Label>
              <Col sm={7}>
                <Switch
                  checked={parseBoolean(reportSettings.enabledFinancialReport)}
                  onChange={(checked) => onToggle('enabledFinancialReport', checked)}
                />
              </Col>
            </FormGroup>

            <Collapse isOpen={parseBoolean(reportSettings.enabledFinancialReport)}>
              <FormGroup row id="enabledFinancialHighlightsContainer">
                <Label sm={5} className="py-0">{i18n.t('financialHighlights', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(reportSettings.enabledFinancialHighlights)}
                    onChange={(checked) => onToggle('enabledFinancialHighlights', checked)}
                  />
                </Col>
              </FormGroup>
            </Collapse>

            <FormGroup row id="enabledMarketingReportContainer">
              <Label sm={5} className="py-0">{i18n.t('marketingReport', i18nOpts)}</Label>
              <Col sm={7}>
                <Switch
                  checked={parseBoolean(reportSettings.enabledMarketingReport)}
                  onChange={(checked) => onToggle('enabledMarketingReport', checked)}
                />
              </Col>
            </FormGroup>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

ReportSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

ReportSettings.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default ReportSettings;
