import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import StatusIndicator from '../../../../global/StatusIndicator';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';
import LotMapSettings from './LotMapSettings';

const i18nOpts = { scope: 'components.admin.communities.show.overview.index' };

const Overview = ({ community }) => {
  if (!community) return null;
  const creatorName = getCreatorDisplayName(community);
  const lastModifierName = getLastModifierDisplayName(community);

  return (
    <div>
      {!v.isBlank(community.hashId) && (
        <div className="mb-4">
          <div className="label">{i18n.t('communityId', i18nOpts)}</div>
          <div><strong>{community.hashId}</strong></div>
        </div>
      )}

      {community.city && (
        <>
          <div className="mb-4">
            <div className="label">{i18n.t('city', i18nOpts)}</div>
            <div><strong>{community.city.name}</strong></div>
          </div>

          {!v.isBlank(community.city.state) && (
            <div className="mb-4">
              <div className="label">{i18n.t('province', i18nOpts)}</div>
              <div><strong>{community.city.state}</strong></div>
            </div>
          )}
        </>
      )}

      {!v.isBlank(community.subRegion) && (
        <div className="mb-4">
          <div className="label">{i18n.t('subRegion', i18nOpts)}</div>
          <div><strong>{community.subRegion}</strong></div>
        </div>
      )}

      {!v.isBlank(community.address) && (
        <div className="mb-4">
          <div className="label">{i18n.t('address', i18nOpts)}</div>
          <div><strong>{community.address}</strong></div>
        </div>
      )}

      {!v.isBlank(community.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{community.description}</strong></div>
        </div>
      )}

      {!v.isBlank(community.websiteUrl) && (
        <div className="mb-4">
          <div className="label">{i18n.t('websiteUrl', i18nOpts)}</div>
          <div><strong>{community.websiteUrl}</strong></div>
        </div>
      )}

      <LotMapSettings community={community} />

      <div className="mb-4">
        <div className="label">{i18n.t('status', i18nOpts)}</div>
        <div>
          <div className="d-inline-block mr-2">
            <StatusIndicator status={community.status} displayTooltip={false} />
          </div>
          <strong>{i18n.t(`statuses.${community.status.toLowerCase()}`)}</strong>
        </div>
      </div>

      {!v.isBlank(community.salesContactName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('salesContactName', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{community.salesContactName}</strong></div>
        </div>
      )}

      {!v.isBlank(community.salesAddress) && (
        <div className="mb-4">
          <div className="label">{i18n.t('salesAddress', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{community.salesAddress}</strong></div>
        </div>
      )}

      {!v.isBlank(creatorName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
          <div><strong>{creatorName}</strong></div>
        </div>
      )}

      {!v.isBlank(lastModifierName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
          <div><strong>{lastModifierName}</strong></div>
        </div>
      )}

      {!v.isBlank(community.externalId) && (
        <div className="mb-4">
          <div className="label">{i18n.t('externalId', i18nOpts)}</div>
          <div><strong>{community.externalId}</strong></div>
        </div>
      )}
    </div>
  );
};

export default connect((store) => ({
  community: store.communities.community
}), {})(Overview);
