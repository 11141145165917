import api from '../../../../../api';
import { getUtmSourceReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';

export function fetchUtmSourceReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmSourceReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmSource: 'SMS Invitation', visitorsCount: fakeValue(100) },
    { utmSource: 'Email Invitation', visitorsCount: fakeValue(100) },
    { utmSource: 'Website', visitorsCount: fakeValue(100) },
    { utmSource: 'Campaign', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}
