import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { Tabs } from '../utils';
import { isMarketingReportEnabled } from '../../../../utils/companyUtils';

const i18nOpts = { scope: 'components.admin.insights.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        active={activeTab === Tabs.VERIFICATIONS}
        onClick={() => onChange(Tabs.VERIFICATIONS)}
      >
        {i18n.t('verifications', i18nOpts)}
      </NavLink>
    </NavItem>
    {isMarketingReportEnabled() && (
      <NavItem>
        <NavLink
          active={activeTab === Tabs.MARKETING}
          onClick={() => onChange(Tabs.MARKETING)}
        >
          {i18n.t('marketing', i18nOpts)}
        </NavLink>
      </NavItem>
    )}
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
