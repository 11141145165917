import api from '../../../../../api';
import { getUtmCampaignReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';

export function fetchUtmCampaignReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmCampaignReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmCampaign: 'Google', visitorsCount: fakeValue(100) },
    { utmCampaign: 'Bing', visitorsCount: fakeValue(100) },
    { utmCampaign: 'Yahoo', visitorsCount: fakeValue(100) },
    { utmCampaign: 'Other', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}
