import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Card, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import momentTz from 'moment-timezone';

import styles from './VerifiedLeadsLocationCard.module.scss';
import {
  fetchVerifiedLeadsLocationAsync,
  generateSampleDataAsync
} from './utils';
import ReportEmpty from '../../../../common/ReportEmpty';
import GoogleHeatmap from '../../../../global/GoogleHeatmap';

const i18nOpts = { scope: 'components.admin.insights.verifications.verifiedLeadsLocationCard.index' };

const VerifiedLeadsLocationCard = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(true);
  const [verifiedLeadLocations, setVerifiedLeadLocations] = useState([]);

  const loadData = () => {
    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((items) => setVerifiedLeadLocations(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const timezone = momentTz.tz.guess();
    const variables = { companyId: company?.id || null, customDateRange: dateRange, timezone };
    return fetchVerifiedLeadsLocationAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  return (
    <Card body className="h-100">
      <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

      <div className="h-100 d-flex justify-content-center align-items-center">
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            {verifiedLeadLocations.length ? (
              <div className={styles.mapContainer}>
                <GoogleHeatmap positions={verifiedLeadLocations} />
              </div>
            ) : (
              <ReportEmpty loading={loading} items={verifiedLeadLocations} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

VerifiedLeadsLocationCard.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VerifiedLeadsLocationCard.defaultProps = {
  company: null
};

export default VerifiedLeadsLocationCard;
