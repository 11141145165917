import React, { useState } from 'react';
import { useTimezoneSelect } from 'react-timezone-select';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

import { DEFAULT_TIMEZONE } from '../../../constants';

const TimezoneSelector = ({ defaultTimezone, onTimezoneChange }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone || DEFAULT_TIMEZONE);

  const { options, parseTimezone } = useTimezoneSelect({});

  const onChange = (tz) => {
    const { value: timezone } = tz;
    setSelectedTimezone(timezone);
    onTimezoneChange(timezone);
  };

  return (
    <div>
      <Input
        type="select"
        name="timezone"
        id="timezone"
        value={selectedTimezone}
        onChange={(e) => onChange(parseTimezone(e.currentTarget.value))}
      >
        {
          options.map((option) => (
            <option key={`option-${option.value}`} value={option.value}>{option.label}</option>
          ))
        }
      </Input>
    </div>
  );
};

TimezoneSelector.propTypes = {
  defaultTimezone: PropTypes.string,
  onTimezoneChange: PropTypes.func
};

TimezoneSelector.defaultProps = {
  defaultTimezone: '',
  onTimezoneChange: () => {}
};

export default TimezoneSelector;
