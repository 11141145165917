import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.advancedSettings' };

export function getSettings() {
  const settings = [
    {
      id: 'contactStages',
      label: i18n.t('index.contactStages', i18nOpts),
      containerId: 'contactStagesContainer'
    },
    {
      id: 'defaultStage',
      label: i18n.t('index.defaultStage', i18nOpts),
      containerId: 'defaultStageContainer'
    },
    {
      id: 'homePriceDisplayMode',
      label: i18n.t('index.homePriceDisplayMode', i18nOpts),
      containerId: 'homePriceDisplayModeContainer'
    },
    {
      id: 'leadNotification',
      label: i18n.t('leadNotifications.index.leadNotification', i18nOpts),
      containerId: 'leadNotificationContainer'
    }
  ];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'enableDemoModeForReserveNow',
        label: i18n.t('index.demoModeReserveNow', i18nOpts),
        containerId: 'enableDemoModeForReserveNowContainer'
      },
      {
        id: 'enableDemoModeForVerified',
        label: i18n.t('index.demoModeVerified', i18nOpts),
        containerId: 'enableDemoModeForVerifiedContainer'
      }
    ]);

    if (isMasterAdmin()) {
      settings.push(...[
        {
          id: 'exportCustomers',
          label: i18n.t('exportSettings.index.exportCustomers', i18nOpts),
          containerId: 'exportCustomersContainer'
        },
        {
          id: 'enableReports',
          label: i18n.t('reportSettings.index.enableReports', i18nOpts),
          containerId: 'enableReportsContainer'
        },
        {
          id: 'enabledFinancialReport',
          label: i18n.t('reportSettings.index.financialReports', i18nOpts),
          containerId: 'enabledFinancialReportContainer'
        },
        {
          id: 'enabledFinancialHighlights',
          label: i18n.t('reportSettings.index.financialHighlights', i18nOpts),
          containerId: 'enabledFinancialHighlightsContainer'
        },
        {
          id: 'enabledMarketingReport',
          label: i18n.t('reportSettings.index.marketingReport', i18nOpts),
          containerId: 'enabledMarketingReportContainer'
        }
      ]);
    }
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.ADVANCED_SETTINGS
  }));
}
