import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Card, Spinner } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import i18n from 'i18n-js';

import { fetchUtmCampaignReportAsync, generateSampleDataAsync } from './utils';
import ReportEmpty from '../../../../common/ReportEmpty';
import { innerRingChartColors, chartOptions } from '../utils';

const momentTz = require('moment-timezone');

const i18nOpts = { scope: 'components.admin.insights.marketing.utmCampaignCard.index' };

function getChartData(utmCampaignData) {
  const formattedData = utmCampaignData.map((c) => ({
    ...c,
    unconvertedCount: c.visitorsCount - c.leadsCount
  }));

  const innerRingColors = innerRingChartColors.slice(0, utmCampaignData.length);
  const outerRingColors = innerRingColors.flatMap((c) => [c, '#CCCCCC']);

  const leadsLabel = i18n.t('leads', i18nOpts);
  const unconvertedLabel = i18n.t('unconverted', i18nOpts);

  return {
    datasets: [
      {
        label: 'Leads vs Unconverted',
        backgroundColor: outerRingColors,
        data: formattedData.flatMap((c) => [c.leadsCount, c.unconvertedCount]),
        labels: formattedData.flatMap(() => [leadsLabel, unconvertedLabel])
      },
      {
        label: 'UTM Values',
        backgroundColor: innerRingColors,
        data: formattedData.map((c) => c.visitorsCount),
        labels: formattedData.map((c) => c.utmCampaign)
      }
    ]
  };
}

const UtmCampaignCard = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(false);
  const [utmCampaignData, setUtmCampaignData] = useState([]);

  const loadData = () => {
    setLoading(true);

    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((data) => {
        setUtmCampaignData(data);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const timezone = momentTz.tz.guess();
    const variables = { companyId: company?.id || null, customDateRange: dateRange, timezone };
    return fetchUtmCampaignReportAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  const data = useMemo(() => getChartData(utmCampaignData), [utmCampaignData]);

  return (
    <Card body>
      <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

      <div className="h-100 d-flex justify-content-center align-items-center">
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            {utmCampaignData.length > 0 ? (
              <Doughnut data={data} options={chartOptions} />
            ) : (
              <ReportEmpty loading={loading} items={utmCampaignData} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

UtmCampaignCard.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

UtmCampaignCard.defaultProps = {
  company: null
};

export default UtmCampaignCard;
