import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import UtmSourceCard from './UtmSourceCard';
import UtmCampaignCard from './UtmCampaignCard';
import UtmContentCard from './UtmContent';
import UtmMediumCard from './UtmMedium';

const Marketing = ({ company, dateRange }) => (
  <Row>
    <Col xs={12} md={6}>
      <UtmSourceCard company={company} dateRange={dateRange} />
    </Col>
    <Col xs={12} md={6}>
      <UtmCampaignCard company={company} dateRange={dateRange} />
    </Col>
    <Col xs={12} md={6}>
      <UtmContentCard company={company} dateRange={dateRange} />
    </Col>
    <Col xs={12} md={6}>
      <UtmMediumCard company={company} dateRange={dateRange} />
    </Col>
  </Row>
);

Marketing.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

Marketing.defaultProps = {
  company: null
};

export default Marketing;
