import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Verifications.module.scss';
import { isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import VerifiedAgeDemographicCard from './VerifiedAgeDemographicCard';
import VerifiedFunnelCard from './VerifiedFunnelCard';
import VerifiedBuyingPowerCard from './VerifiedBuyingPowerCard';
import VerifiedLeadsLocationCard from './VerifiedLeadsLocationCard';

const Verifications = ({ company, dateRange }) => {
  const isSuperAdmin = isMasterAdmin() || isTecSupport();

  return (
    <Row className={classNames('align-items-stretch')}>
      <Col xs="12" sm="4" className={styles.col}>
        <VerifiedAgeDemographicCard company={company} dateRange={dateRange} />
      </Col>
      <Col xs="12" sm="8" className={styles.col}>
        <VerifiedFunnelCard company={company} dateRange={dateRange} />
      </Col>
      {isSuperAdmin && (
        <Col xs="12" sm="7" className={styles.col}>
          <VerifiedBuyingPowerCard company={company} dateRange={dateRange} />
        </Col>
      )}
      <Col xs="12" sm="5" className={styles.col}>
        <VerifiedLeadsLocationCard company={company} dateRange={dateRange} />
      </Col>
    </Row>
  );
};

Verifications.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

Verifications.defaultProps = {
  company: null
};

export default Verifications;
