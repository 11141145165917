import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18n-js';
import { Card, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import momentTz from 'moment-timezone';

import { fetchVerifiedFunnelAsync, generateSampleDataAsync, getGradient } from './utils';
import ReportEmpty from '../../../../common/ReportEmpty';
import FunnelChart from './FunnelChart';

const i18nOpts = { scope: 'components.admin.insights.verifications.verifiedFunnelCard.index' };

const VerifiedFunnelCard = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(true);
  const [verifiedCustomers, setVerifiedCustomers] = useState([]);

  const getLabel = (chartItem) => i18n.t(`labels.${chartItem.name}`, i18nOpts);

  const { data, options } = useMemo(() => {
    const labels = verifiedCustomers.map((vc) => getLabel(vc));
    const values = verifiedCustomers.map((vc) => vc.customersCount);

    return {
      data: {
        labels,
        datasets: [{
          data: values,
          backgroundColor(context) {
            const { ctx, chartArea } = context.chart;
            if (!chartArea) return null; // This case happens on initial chart load

            return getGradient(ctx, chartArea);
          }
        }],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            position: 'top',
            display: true,
            grid: {
              display: true,
              lineWidth: 2,
              color: '#EAECF0',
              drawBorder: false,
            },
            ticks: {
              font: {
                size: 12,
                weight: '500'
              },
              color: '#000000',
              callback(value, index, ticks) {
                const chartItem = verifiedCustomers[index];
                const label = getLabel(chartItem);
                const count = chartItem.customersCount;
                return [label, count];
              }
            }
          },
        }
      }
    };
  }, [verifiedCustomers]);

  const loadData = () => {
    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((items) => setVerifiedCustomers(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const timezone = momentTz.tz.guess();
    const variables = { companyId: company?.id || null, customDateRange: dateRange, timezone };
    return fetchVerifiedFunnelAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  return (
    <Card body className="h-100">
      <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

      <div className="h-100 d-flex justify-content-center align-items-center">
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            {verifiedCustomers.length ? (
              <FunnelChart data={data} options={options} />
            ) : (
              <ReportEmpty loading={loading} items={verifiedCustomers} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

VerifiedFunnelCard.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VerifiedFunnelCard.defaultProps = {
  company: null
};

export default VerifiedFunnelCard;
